@import url("https://fonts.googleapis.com/css?family=Baloo+2:400,700,600");

*{
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --black: rgba(0, 0, 0, 1);
  --gossamer: rgba(13, 151, 124, 1);
  --caribbean-green: rgba(28, 192, 160, 1);
  --blackcurrant: rgba(49, 43, 62, 1);
  --manatee: rgba(153, 156, 173, 1);
  --red: rgb(187, 10, 10);
  --yellow: rgb(251, 211, 101);
  --cultured-pearl: rgba(245, 245, 245, 1);
  --white: rgba(255, 255, 255, 1);
  --sky-blue:rgb(45, 200, 216);
  --font-size-s: 14px;
  --font-size-m: 18px;
  --font-size-l: 24px;
  --font-size-xl: 36px;
  --font-size-xxl: 64px;
  --font-size-xxxl: 96px;
  --font-family-baloo_2: "Baloo 2";
}

.baloo2-semi-bold-white-24px {
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-1);
  font-weight: 600;
  font-style: normal;
}

.baloo2-normal-gossamer-18px {
  color: var(--gossamer);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}


.baloo2-semi-bold-gossamer-18px {
  color: var(--gossamer);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}

.baloo2-normal-gossamer-24px {
  color: var(--gossamer);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}

.baloo2-semi-bold-gossamer-64px {
  color: var(--gossamer);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-xxl);
  font-weight: 600;
  font-style: normal;
}

.baloo2-normal-manatee-24px {
  color: var(--manatee);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-l);
  font-weight: normal;
  font-style: normal;
}

.baloo2-semi-bold-blackcurrant-18px {
  color: var(--blackcurrant);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal,
}

.baloo2-semi-bold-sky-blue-18px {
  color: var(--sky-blue);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal,
}

.baloo2-yellow-18px {
  color: #FFB800;
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal,
}

.baloo2-semi-bold-black-18px {
  color: var(--black);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal,
}

.baloo2-normal-black-18px {
  color: var(--black);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-m);
  font-weight: 200;
  font-style: normal,
}

.baloo2-semi-bold-white-18px-2 {
  color: var(--white);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}

.baloo2-semi-bold-white-36px {
  color: var(--white);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-xl);
  font-weight: 600;
  font-style: normal;
}

.baloo2-semi-bold-blackcurrant-24px {
  color: var(--blackcurrant);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-l);
  font-weight: 600;
  font-style: normal;
}

.baloo2-semi-bold-black-24px  {
  color: var(--black);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-l);
  font-weight: 600;
  font-style: normal;
}

.baloo2-semi-bold-black-14px  {
  color: var(--black);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}

.baloo2-white-14px  {
  color: var(--white);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}

.baloo2-semi-bold-white-18px {
  color: var(--white);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}

.baloo2-normal-manatee-14px {
  color: var(--manatee);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}


.baloo2-semi-bold-manatee-14px {
  color: var(--manatee);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
}

.baloo2-normal-caribbean-green-14px {
  color: var(--caribbean-green);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}

.baloo2-normal-caribbean-green-10px {
  color: var(--caribbean-green);
  font-family: var(--font-family-baloo_2);
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
}
.baloo2-normal-caribbean-green-18px {
  color: var(--caribbean-green);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}

.baloo2-bold-caribbean-green-18px {
  color: var(--caribbean-green);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}


.baloo2-semi-bold-caribbean-green-14px {
  color: var(--caribbean-green);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
}

.baloo2-normal-manatee-12px {
  color: var(--manatee);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.baloo2-normal-white-12px {
  color: var(--white);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}

.baloo2-semi-bold-caribbean-green-36px {
  color: var(--caribbean-green);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-xl);
  font-weight: 600;
  font-style: normal;
}

.baloo2-semi-bold-black-36px {
  color: var(---black);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-xl);
  font-weight: 600;
  font-style: normal;
  line-height:1.2;
}

.baloo2-semi-bold-white-24px-2 {
  color: var(--white);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-l);
  font-weight: 600;
  font-style: normal;
}

.baloo2-normal-manatee-18px {
  color: var(--manatee);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}



.baloo2-semi-bold--caribbean-green-18px {
  color: var(--caribbean-green);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}

.baloo2-semi-bold-blackcurrant-36px {
  color: var(--blackcurrant);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-xl);
  font-weight: 600;
  font-style: normal;
  line-height: 100%;
}

.baloo2-normal-white-black-18px {
  color: '#312B3E';
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-s);
  font-weight: 100;
  font-style: normal;
}

.baloo2-semi-bold-manatee-18px {
  color: var(--manatee);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}

.baloo2-normal-white-16px {
  color: var(--white);
  font-family: var(--font-family-baloo_2);
  font-size: 28px;
  font-weight: 600;
  font-style: normal;
  text-shadow: 2px 4px 4px var(--manatee);
}

.baloo2-semi-bold-manatee-24px {
  color: var(--manatee);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-l);
  font-weight: 600;
  font-style: normal;
}

.hide-on-small-screen {
  visibility: visible;
}

@media screen and (max-width: 700px) {
  .hide-on-small-screen {
    visibility: hidden;
    display: none;
  }
}


.hide-on-medium-screen {
  visibility: visible;
}

@media screen and (max-width: 800px) {
  .hide-on-medium-screen {
    visibility: hidden;
    display: none;
  }
}

.hide-on-big-screen {
  visibility: visible;
}

@media screen and (max-width: 1100px) {
  .hide-on-big-screen {
    visibility: hidden;
    display: none;
  }
}

.show-on-big-screen{
  visibility: hidden;
  display: none;
}

@media screen and (max-width: 1100px) {
  .show-on-big-screen {
    visibility: visible;
    display: inline;
  }
}

.card-with-shadow { 
	box-shadow:0px 6.462686538696289px 19.388059616088867px rgba(51.00000075995922, 42.000001296401024, 64.00000378489494, 0.10000000149011612);
	background-color:rgba(255, 255, 255, 1);
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
}

.heart-filled path {
  fill: #ffffff; /* Change to the filled heart color */
}

.heart-empty path {
  fill: #999CAD; /* Change to the empty heart color */
}
