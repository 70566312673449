/* Your CSS stylesheet */
.active-page {
    background-color: #1cc0a0; /* Set the background color for the active page */
    color: #fff; /* Set the text color for the active page */
    border : 0px solid #fff; /* Set a border for the active page if needed */
   border-bottom: 5px solid #fff ;
    padding: 13px;
    padding-bottom: 6px;
    border-radius: 7%;
    font-size:xx-large;
  }
  .pagination-container {
    display: flex;
    justify-content:space-between;
    align-items: center;
    margin-top: 20px;
    padding-top : 16px;
    padding-bottom : 16px;
    padding-inline: 16px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    background-color:#1cc0a0;
    /* Add other styling properties as needed */
  } 
  