.popular-category-grid{
    display: grid;
    gap: 0.5rem;
    margin-inline: 32px;
    grid-template-columns: repeat(auto-fit,minmax(120px,1fr));
    grid-auto-rows: 280px;
}

.popular-category-grid-item-with-2row {
    grid-row: span 2 / auto;
}

.grid-item-with-2column {
    grid-column: span 2 / auto;
}

@media screen and (min-width: 500px) {
    .popular-category-grid{
        grid-template-columns: repeat(auto-fit,minmax(180px,1fr));
    }
  }

@media screen and (min-width: 740px) {
    .popular-category-grid{
        grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
    }
  }

  @media screen and (min-width: 820px) {
    .popular-category-grid{
        grid-template-columns: repeat(auto-fit,minmax(340px,1fr));
    }
  }