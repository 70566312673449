.statistic-card-with-shadow { 
	box-shadow:0px 6.462686538696289px 19.388059616088867px rgba(51.00000075995922, 42.000001296401024, 64.00000378489494, 0.10000000149011612);
	background-color:rgba(255, 255, 255, 1);
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
}

.statistic-grid{
  display: grid;
  gap: 1rem;
  margin-inline: 32px;
  grid-template-columns: repeat(auto-fit,minmax(380px,1fr));
}


@media screen and (max-width: 500px) {
  .statistic-grid{
      grid-template-columns: 1fr;
  }
}


@media screen and (max-width: 740px) {
  .statistic-grid{
      grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
  }
}

@media screen and (max-width: 820px) {
  .statistic-grid{
      grid-template-columns: repeat(auto-fit,minmax(340px,1fr));
  }
}


.statistic-number-grid{
  display: grid;
  gap: 1rem;
  margin-inline: 32px;
  grid-template-columns: repeat(auto-fit,minmax(240px,1fr));
}


@media screen and (max-width: 480px) {
  .statistic-number-grid{
      grid-template-columns: 1fr;
  }
}


@media screen and (max-width: 550px) {
  .statistic-number-grid{
      grid-template-columns: repeat(auto-fit,minmax(160px,1fr));
  }
}

@media screen and (max-width: 720px) {
  .statistic-number-grid{
      grid-template-columns: repeat(auto-fit,minmax(240px,1fr));
  }
}


.statistics-numbers-with-green-color{
  padding-inline: 32px;
  margin-top: 40px;
  background-color: #C4EEE6;
}

