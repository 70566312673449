

.reward-card-image { 
	width:100%;
	height:170px;
    border-radius:12px;
	background-repeat:no-repeat;
	background-size:cover;
}

@media screen and (min-width: 700px) {
    .card-shadow {
        width: "18%";
    }
  }




.e1_1998 { 
	background-image:linear-gradient(0deg, rgba(255, 183.5999920964241, 0, 1) 0%) ;
	-webkit-background-clip:text;
	-webkit-text-fill-color:transparent;
	-moz-background-clip:text;
	-moz-text-fill-color:transparent;
	-ms-background-clip:text;
	-ms-text-fill-color:transparent;
	width:179px;
	height:28px;
	position:absolute;
	left:34.341796875px;
	top:76px;
	font-family:Baloo 2;
	text-align:left;
	font-size:18px;
	letter-spacing:0;
}
