@media screen and (min-width: 700px) {
  .merchant-details-layout {
      display: flex;
  }
}

.half-merchant-details-layout-1{
	width: 100%;
}

.half-merchant-details-layout-2{
	width: 100%;
}

@media screen and (min-width: 700px) {
  .half-merchant-details-layout-1 {
      width: 63%;
  }
 
  .half-merchant-details-layout-2{
    width: 33%;
  }
}

.offer-grid-card-image { 
	max-height:220px;
    width: 100%;
	background-repeat:no-repeat;
	background-size:contain;
  object-fit: cover;
}