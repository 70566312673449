.header {
  align-items: center;
  background-color: var (--white);
  box-shadow: 0px 2px 15px 7#00000026;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 80px;
  justify-content: flex-end;
  min-width: 1440px;
  mix-blend-mode: normal;
  padding: 8.6px 42.1px;
  position: relative;
  width: auto;
}

.navbar {
  align-items: flex-start;
  display: flex;
  gap: 24px;
  margin-inline-start: 99px;
  margin-top: 0;
  width: fit-content;
}

.navbar-link-place {
  color: var(--caribbean-green);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-m);
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  width: fit-content;
}

.navbar-link {
  letter-spacing: 0;
  line-height: normal;
  width: fit-content;
}

.frame-33850 {
  align-items: center;
  display: flex;
  gap: 24px;
  justify-content: center;
  margin-left: 90px;
  margin-top: 0;
  position: relative;
  width: fit-content;
}

.group-33850 {
  min-height: 36px;
  justify-content: end;
  justify-self: end;
  justify-items: end;
}

.frame-33851 {
  display: flex;
  right: 0px;
  margin-inline-end: min(1vh, 1rem);
  justify-content: end;
  width: fit-content;
  align-items: center;
}


.ellipse-94 {
  height: 36px;
  min-width: 36px;
}

.address {
  color: var(--caribbean-green);
  font-family: var(--font-family-baloo_2);
  font-size: var(--font-size-s);
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
}




.search {
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--manatee);
  border-radius: 10px;
  height: 36px;
  min-width: 230px;
}

.frame-33852 {
  flex-direction: row;
  align-items: center;
  display: flex;
  gap: 6px;
  justify-content: center;
  left: 9px;
  position: relative;
  top: 7px;
  width: fit-content;
}

.search-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  padding-inline-start: 8px;
  width: fit-content;
}

.icon-light-outline-search {
  height: 8px;
  min-width: 16px;
}

.search-2 {
  align-items: center;
  display: flex;
  justify-content: center;
  left: 8px;
  min-width: 14px;
  position: relative;
  top: 12px;
}

.group-container {
  height: 14px;
  position: relative;
  width: 14px;
}

.group-3 {
  height: 13px;
  left: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 13px;
}


.notification {
  background-image: url(../../../_assets/images/notification-9.svg);
  background-size: 100% 100%;
  height: 26px;
  left: 4px;
  position: relative;
  top: 3px;
  width: 26px;
}

.heart {
  background-image: url(../../../_assets/images/heart.svg);
  background-size: 100% 100%;
  height: 22px;
  left: 4px;
  position: relative;
  top: 3px;
  width: 22px;
}

.cart {
  background-image: url(../../../_assets/images/buy.svg);
  background-size: 100% 100%;
  height: 26px;
  left: 4px;
  position: relative;
  top: 3px;
  width: 26px;
}

.home-nav-bar-hide-on-medium-screen {
  visibility: visible;
}

@media screen and (max-width: 600px) {
  .home-nav-bar-hide-on-medium-screen {
    visibility: hidden;
    display: none;
  }
}

.home-nav-bar-hide-on-s-medium-screen {
  visibility: visible;
  display: flex;
}

@media screen and (max-width: 800px) {
  .home-nav-bar-hide-on-s-medium-screen {
    visibility: hidden;
    display: none;
  }
}

.home-nav-bar-hide-on-b-medium-screen {
  visibility: visible;
 
}

@media screen and (max-width: 920px) {
  .home-nav-bar-hide-on-b-medium-screen {
    display: none;
    visibility: hidden;
  }
}

.home-nav-bar-show-on-medium-screen {
  display: none;
  visibility: hidden;
}

@media screen and (max-width: 600px) {
  .home-nav-bar-show-on-medium-screen {
    visibility: visible;
    display: block;
  }
}