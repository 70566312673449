.card{
    background-color: #F3F3F3;
    margin-inline: 16px;
    justify-content: "center";
    align-items: "center";
    border-radius: 8px;
}

@media screen and (min-width: 700px) {
    .card {
        display: flex;
        margin-inline: 0;
        border-radius: 0px;
    }
  }

  
.bottom-slider-grid{
    background-color: #F3F3F3;
    margin-inline: 0;
    border-radius: 0px;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit,minmax(240px,1fr));
  }
  
  
  @media screen and (max-width: 480px) {
    .bottom-slider-grid{
        grid-template-columns: 1fr;
        margin-inline: 32px;

    }
  }
  
  
  @media screen and (max-width: 550px) {
    .bottom-slider-grid{
        margin-inline: 32px;
        grid-template-columns: repeat(auto-fit,minmax(160px,1fr));
    }
  }
  
  @media screen and (max-width: 720px) {
    .bottom-slider-grid{
        margin-inline: 32px;
        grid-template-columns: repeat(auto-fit,minmax(240px,1fr));
    }
  }
  