.about_us{
    margin-inline: 32px;
    margin-top: 40px;
}

@media screen and (min-width: 700px) {
    .about_us {
        display: flex;
    }
}

@media screen and (min-width: 700px) {
    .half_about_us {
        width: 50%;
    }
}
