.products-grid{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
  }
  
  
  @media screen and (max-width: 500px) {
    .products-grid{
        grid-template-columns: 1fr;
    }
  }
  
  
  @media screen and (max-width: 740px) {
    .products-grid{
        grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
    }
  }
  
  @media screen and (max-width: 820px) {
    .products-grid{
        grid-template-columns: repeat(auto-fit,minmax(340px,1fr));
    }
  }

  .products-grid-with-one-column{
    display: grid;
    grid-template-columns: 1fr;
  }

  .products-grid-card-with-shadow { 
	box-shadow:0px 6.462686538696289px 19.388059616088867px rgba(51.00000075995922, 42.000001296401024, 64.00000378489494, 0.10000000149011612);
	background-color:rgba(255, 255, 255, 1);
    width: 100%;
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
}

.products-grid-card-image { 
	max-height:190px;
    width: 100%;
    border-radius: 7px;
	background-repeat:no-repeat;
	background-size:contain;
  object-fit: cover;
}