/* src/BookingModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 90%;
    max-width: 500px;
    max-height: 90%;
    overflow-y: auto;
    position: relative;
  }
  
  .modal-content h2 {
    margin-top: 0;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  label {
    display: flex;
    flex-direction: column;
  }
  
  input, textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  textarea {
    resize: vertical;
  }
  
  button[type="submit"] {
    background-color: #000080;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button[type="submit"]:hover {
    background-color: #000070;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    color: rgb(197, 13, 13);
    font-size: larger;
    font-weight: bolder;
    border: none;
    padding: 10px;
    width:40px;
    height:40px;
    border-radius: 50%;
    cursor: pointer;
  }
  