.rewards{
    margin-inline: 32px;
    margin-top: 40px;
}

@media screen and (min-width: 700px) {
    .rewards {
        display: flex;
    }
}

@media screen and (min-width: 700px) {
    .half_reward {
        width: 50%;
    }
}

  .rewardCard{
    margin-inline: 0;
    border-radius: 8px;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit,minmax(240px,1fr));
  }
  
  
  @media screen and (max-width: 480px) {
    .rewardCard{
        grid-template-columns: 1fr;
        margin-inline: 32px;

    }
  }
  
  
  @media screen and (max-width: 550px) {
    .rewardCard{
        margin-inline: 32px;
        grid-template-columns: repeat(auto-fit,minmax(160px,1fr));
    }
  }
  
  @media screen and (max-width: 720px) {
    .rewardCard{
        margin-inline: 32px;
        grid-template-columns: repeat(auto-fit,minmax(240px,1fr));
    }
  }
  