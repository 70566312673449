.checkbox-container{
    display: flex;
    align-items: center;
}

.checkbox-container span{
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.checkbox-container input[type='checkbox']{
    cursor: pointer;
    opacity: 0;
    position: absolute;
}

.titleDetails {
  color: white;
  font-size: 12px;
  font-weight:400;
}

.valueName {
  color: white;
  font-size: 21px;
  font-weight:bolder;
  overflow-wrap: break-word; /* Allow text to wrap if necessary */
  width: 300px;
  text-align: center;
}
.valueDetails {
  color: white;
  font-size: 18px;
  font-weight:bolder;
}

.textname {
color: white;
}

.checkbox-container input[type='checkbox']:checked + span::before{
    background-color: var(--caribbean-green);
    border: none;
    width: 1.1rem;
    height: 1.1rem;
    color: white;
    content: '\002714';
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
}

.checkbox-container span::before{
    content: '';
    width: 1rem;
    height: 1rem;
    border: 0.09rem solid var(--blackcurrant);
    border-radius: 4px;
    margin-inline-end: 16px;
}

.checkbox-container span:hover::before{
    background-color: rgb(35, 231, 192);
}

.merchants-grid-with-one-column{
    display: grid;
    grid-template-columns: 1fr;
  }

.merchants-grid{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
  }
  
  
  @media screen and (max-width: 500px) {
    .merchants-grid{
        grid-template-columns: 1fr;
    }
  }
  
  
  @media screen and (max-width: 740px) {
    .merchants-grid{
        grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
    }
  }
  
  @media screen and (max-width: 820px) {
    .merchants-grid{
        grid-template-columns: repeat(auto-fit,minmax(340px,1fr));
    }
  }

  
  .merchants-grid-card-with-shadow { 
	box-shadow:0px 6.462686538696289px 19.388059616088867px rgba(51.00000075995922, 42.000001296401024, 64.00000378489494, 0.10000000149011612);
	background-color:rgba(255, 255, 255, 1);
    width: 100%;
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
}

.merchants-grid-card-image { 
	max-height:120px;
    width: 100%;
	border-top-left-radius:12px;
	border-top-right-radius:12px;
	background-repeat:no-repeat;
	background-size:contain;
    object-fit: contain;
}

.merchants-card{
    position: "relative";
    justify-content: center;
    align-items: center;
    text-align: center;
    align-content: center;
    min-width: 240px;
  }


@media screen and (max-width: 500px) {
    .merchants-card {
      min-width: 435px;
    }
  }
  
  .merchants-card-with-shadow { 
	box-shadow:0px 6.462686538696289px 19.388059616088867px rgba(51.00000075995922, 42.000001296401024, 64.00000378489494, 0.10000000149011612);
	background-color:rgba(255, 255, 255, 1);
    width: 100%;
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
}

.merchants-card-image { 
	width:100%;
	height:170px;
	border-top-left-radius:12px;
	border-top-right-radius:12px;
	background-repeat:no-repeat;
	background-size:cover;
}



